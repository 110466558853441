import PageMetadata from "../components/seo/page-metadata.interface"

/**
 * Adds a page number text to the title but only in cases when the
 * page if higher than 1. When it equals 1, leave the original title
 * so it looks better on the initial pages.
 *
 * @example
 * addPageToMeta({title: "My Blog"}, 2) // => {title: "My Blog | Page 2"}
 *
 * @param meta Metadata of the page.
 * @param page Page number
 * @returns PageMetadata
 */
const addPageToMeta = (meta: PageMetadata, page = 0): PageMetadata => {
  if (page > 1) {
    return {
      ...meta,
      title: `${meta.title} | Page ${page}`,
      description: `${meta.description} | Page ${page}`,
      facebookDescription: `${meta.description} | Page ${page}`,
      twitterDescription: `${meta.description} | Page ${page}`,
    }
  } else {
    return meta
  }
}

export default addPageToMeta
