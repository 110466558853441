import { useEffect } from "react"

const CookiePolicyScript = () => {
  useEffect(() => {
    const script = document.createElement("script")
    script.id = "CookieDeclaration"
    script.src =
      "https://consent.cookiebot.com/630a0427-48db-46c0-ab1c-22626be3e802/cd.js"
    script.type = "text/javascript"
    script.async = true
    document.body.appendChild(script)

    return () => {
      if (script) {
        document.body.removeChild(script)
      }
      const generatedDiv = document.querySelector(".CookieDeclaration")
      if (generatedDiv) {
        generatedDiv.remove()
      }
    }
  }, [])
  return null
}

export default CookiePolicyScript
